import React, { useEffect } from "react";
import AOS from "aos"
import 'aos/dist/aos.css'

function WaterDamage() {
    useEffect(() => {
        AOS.init()
    }, [])

    const data = [
        {
            id: 1,
            img: '../assets/Galery/WaterDamage/1.jpeg'
        },
        {
            id: 2,
            img: '../assets/Galery/WaterDamage/2.jpeg'
        },
        {
            id: 3,
            img: '../assets/Galery/WaterDamage/3.jpeg'
        },
        {
            id: 4,
            img: '../assets/Galery/WaterDamage/4.jpeg'
        },
        {
            id: 5,
            img: '../assets/Galery/WaterDamage/5.jpeg'
        },
        {
            id: 6,
            img: '../assets/Galery/WaterDamage/6.jpeg'
        }
    ]

    const photos = data.map(photo => {
        return (
            <>
                <div>
                    <img class="h-96 w-full object-cover rounded-lg hover:scale-105 duration-200"
                        src={photo.img} alt="Image1" />
                </div>
            </>
        )
    })

    return (
        <>
            {photos}
        </>
    )

}

export default WaterDamage;