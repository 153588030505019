import React from 'react';

function About() {
    return (
        <>
            <div>
                {/* Hero Section */}
                <section className="bg-black text-white py-20">
                    <div className="container mx-auto text-center">
                    <h1 className="text-5xl font-bold mb-4 text-green-fos">About BC Quality General Contractor</h1>
                    <p className="text-xl mb-8 px-5">We bring your home renovation dreams to life with professionalism, quality, and care.</p>
                    </div>
                </section>

                {/* Company Overview Section */}
                <section className="py-20 bg-gray-100">
                    <div className="container mx-auto px-6 md:px-20">
                    <h2 className="text-4xl font-bold text-center text-green-titles mb-10">Who We Are</h2>
                    <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
                        <img src="../assets/carousel/one.jpeg" alt="Team at work" className="w-full md:w-1/2 rounded-lg shadow-lg" />
                        <div className="md:w-1/2 md:pl-10">
                        <p className="text-lg text-gray-700 text-justify mb-6">
                        At BC Quality General Contractor, we are committed to providing high-quality renovation and construction services that exceed customer expectations. Our team specializes in a wide range of services, including exterior and interior painting, remodeling, water damage restoration, and much more.
                        </p>
                        </div>
                    </div>
                    </div>
                </section>

                {/* Mission and Vision Section */}
                <section className="py-20 bg-white">
                    <div className="container mx-auto text-center">
                    <h2 className="text-4xl font-bold text-green-titles mb-10">Our Mission & Vision</h2>
                    <div className="flex flex-col md:flex-row space-y-10 md:space-y-0 justify-center md:gap-8 px-10">
                        <div className="bg-gray-100 p-8 rounded-lg shadow-lg w-full md:w-1/2">
                        <h3 className="text-2xl font-bold text-green-fos mb-4">Mission</h3>
                        <p className="text-lg text-gray-700 text-justify">
                            Our mission is to deliver exceptional construction services, transforming spaces while prioritizing quality, safety,
                            and customer satisfaction. We aim to bring integrity and professionalism to every project we take on.
                        </p>
                        </div>
                        <div className="bg-gray-100 p-8 rounded-lg shadow-lg w-full md:w-1/2">
                        <h3 className="text-2xl font-bold text-green-fos mb-4">Vision</h3>
                        <p className="text-lg text-gray-700 text-justify">
                            We envision a world where every home and business is enhanced by expert craftsmanship and personalized service.
                            Our goal is to become the preferred contractor for those seeking the highest quality renovations and repairs.
                        </p>
                        </div>
                    </div>
                    </div>
                </section>

                {/* Why choose us? */}
                <section className="flex flex-col items-center py-12 px-8 ">
                    <h4 className="text-3xl font-bold mb-8 text-green-titles w-full flex justify-center">Why choose us?</h4>
                    <div className='w-full h-auto grid md:grid-cols-3 gap-4 place-items-center text-black'>
                    <div className='flex flex-col items-center w-[80%] space-y-3 border p-6 rounded-xl bg-white'>
                        <h5 className="text-2xl text-justify font-bold text-green-fos">Expert Team</h5>
                        <i className="bx bxs-star text-4xl mb-4 text-green-fos"></i>
                        <p class='text-justify'>Our experienced professionals ensure top-quality craftsmanship on every project.</p>
                    </div>
                    <div className='flex flex-col items-center w-[80%] space-y-3 border p-6 rounded-xl bg-white'>
                        <h5 className='text-2xl text-justify font-bold text-green-fos'>Faster Response</h5>
                        <i class='text-4xl text-green-fos bx bx-run' ></i>
                        <p class='text-justify'>Our team will arrive as soon as possible to take care of the damage and return your property to its best condition..</p>
                    </div>
                    <div className='flex flex-col items-center w-[80%] space-y-3 border p-6 rounded-xl bg-white'>
                        <h5 className='text-2xl text-justify font-bold text-green-fos'>Guarantee</h5>
                        <i class='text-4xl text-green-fos bx bx-shield' ></i>
                        <p class='text-justify'>We ensure complete customer satisfaction from the fast response at the point of contact to quality service</p>
                    </div>
                    </div>
                </section>

                {/* Call to Action */}
                <section className="py-20 bg-green-titles text-white mt-10">
                    <div className="container mx-auto text-center">
                    <h2 className="text-4xl font-bold mb-4">Ready to Transform Your Home?</h2>
                    <p className="text-lg mb-8 font-semibold">Contact us today and let’s bring your vision to life with quality you can trust.</p>
                    <a href="/contact" className="bg-white font-bold text-green-fos py-3 px-8 rounded-full shadow-lg hover:bg-green-fos hover:text-white transition-all duration-500">
                        Contact us
                    </a>
                    </div>
                </section>
            </div>
        </>
    );
}

export default About;