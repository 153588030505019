function Error() {
    return (
        <>
            <section class="bg-gray-50 py-20">
                <div class="container flex flex-col justify-center items-center mx-auto h-auto">
                    <h1 class="text-5xl font-extrabold text-gray-900 mb-4">404</h1>
                    <p class="text-2xl font-semibold text-gray-600 mb-6">Oops! Page not found.</p>
                    <img src="../assets/error.gif" alt="Error" class="w-[280px] md:w-[350px] hover:scale-110 transition-transform duration-300 ease-in-out rounded-lg shadow-lg mb-6" />
                    <a href="/" class="bg-gradient-to-r from-green-fos to-green-500 text-white text-lg font-medium py-3 px-8 w-64 md:w-72 rounded-full shadow-md hover:scale-105 hover:shadow-lg transition-all duration-300 ease-in-out">
                    Back to Home
                    </a>
                </div>
            </section>
        </>
    )
}

export default Error