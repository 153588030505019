import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, Contact, Services, Projects, About,Error } from './pages'
import { DecksAndFence, InternalAndExternal, Remodeling, WaterDamage } from './Services';
import { Navbar, Footer } from './components';

function App() {
  return (
    <>
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/projects" element={<Projects />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/DecksAndFence" element={<DecksAndFence />} />
          <Route exact path="/InternalAndExternal" element={<InternalAndExternal />} />
          <Route exact path="/Remodeling" element={<Remodeling />} />
          <Route exact path="/WaterDamage" element={<WaterDamage />} />
          <Route exact path="/*" element={<Error/>} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>

  );
}

export default App;


/* 
    const menuButton = document.getElementById('menu-button');
    const mobileMenu = document.getElementById('mobile-menu');

    menuButton.addEventListener('click', () => {
      mobileMenu.classList.toggle('hidden');
    }); */
