import React, { useState } from 'react';
import { Bathroom, Kitchen, Painting, Rooms, WaterDamage } from '../gallery';
import 'boxicons/css/boxicons.min.css';

function Projects() {



    const projects = {
        Bathroom: <Bathroom />,
        Kitchen: <Kitchen />,
        Painting: <Painting />,
        Rooms: <Rooms />,
        WaterDamage: <WaterDamage/>
    }

    const [current, setProject] = useState('Bathroom')

    return (
        <>

            <main className="overflow-x-hidden">
                {/* banner */}
                <section className="w-full h-[300px] grid place-items-center md:flex md:justify-between items-center px-20 bg-black">
                    <div>
                        <h1 className="text-3xl md:text-5xl font-semibold text-center mb-4 text-green-titles p-5">Projects</h1>
                    </div>
                    <div className='bg-banner-two w-[50%] h-[100%] bg-center bg-cover hidden md:block'></div>
                </section>

                <div class="flex items-center justify-center py-4 md:py-8 flex-wrap mt-14">

                    {
                        Object.keys(projects).map((project) => (
                            <button
                                className={`text-green-fos m-3 text-lg border border-green-fos transition-all ${current === project ? 'bg-green-fos text-white' : 'hover:border-green-titles'} px-4 py-2 rounded-3xl font-semibold`}
                                key={project}
                                onClick={() => setProject(project)}
                                type="button">
                                {project}
                            </button>
                        ))
                    }
                </div>

                <div class="grid grid-cols-1 md:grid-cols-3 gap-5 p-10">
                    {projects[current]}
                </div>

            </main>

        </>
    )

};

export default Projects;