import React from "react";

function InternalAndExternal() {
    return (
        <>

            <main>
                <section className="w-full h-96 grid place-items-center md:flex md:justify-between items-center px-20 bg-black">
                    <div>
                        <h1 className="text-3xl md:text-5xl font-semibold text-center mb-4 text-green-titles p-5">Exterior and Interior</h1>
                        <p className="text-white text-lg md:text-xl p-3">
                        Revitalize your home with fresh, vibrant paint.
                        </p>
                    </div>
                    <div className='bg-banner-one w-[50%] h-[100%] bg-center bg-cover hidden md:block'></div>
                </section>

                <section className='w-full p-5 flex-flex-col justify-center items-center space-y-8 my-16'>
                    <h1 className="text-3xl text-center text-green-titles font-semib">
                        Exterior and Interior
                    </h1>

                    <div className="w-[70%] flex flex-col mx-auto justify-center items-center space-y-7">
                        <p className="text-lg md:text-xl text-black text-justify leading-relaxed">
                            When it comes to maintaining or enhancing your home, exterior and interior painting and remodeling are essential. Interior painting allows you to refresh the look of any room, creating a personalized ambiance that suits your taste. A new color scheme can dramatically transform the feel of a space, adding brightness, warmth, or a modern touch. Externally, painting not only boosts curb appeal but also protects your home from environmental factors like rain, wind, and sun exposure.
                        </p>
                        <p className="text-lg md:text-xl text-black text-justify leading-relaxed ">
                            Remodeling, on the other hand, involves more comprehensive changes. Whether you’re updating your kitchen, transforming your bathroom, or adding new rooms, remodeling improves both the aesthetics and functionality of your home. It's an opportunity to adapt your living space to your current needs—perhaps adding energy-efficient solutions or improving the layout for better flow. These updates can enhance your home's comfort, and increase its market value, making remodeling a worthwhile investment.
                        </p>
                        <div className=" flex flex-col gap-4 md:flex-row items-center justify-center">
                            <img src="../assets/Material/painting1.jpg" alt="foton" className="w-full md:w-[50%] h-96" />
                            <img src="../assets/Material/painting2.jpg" alt="foton" className="w-full md:w-[50%] h-96" />
                        </div>
                    </div>

                </section>

                <div className='w-full flex justify-center mb-12 pb-10'>
                    <a href='/services'>
                        <div className='bg-green-titles p-4 w-[200px] rounded-lg text-xl text-white font-semibold flex justify-center hover:bg-green-fos hover:border hover:cursor-pointer transition-all duration-500'>All Services</div>
                    </a>
                </div>
            </main>

        </>
    );
}

export default InternalAndExternal;