import React, { useEffect } from "react";
import AOS from "aos"
import 'aos/dist/aos.css'

function Bathroom() {
    useEffect(() => {
        AOS.init()
    }, [])

    const data = [
        {
            id: 1,
            img: '../assets/Galery/Bathroom/1.jpeg'
        },
        {
            id: 2,
            img: '../assets/Galery/Bathroom/2.jpeg'
        },
        {
            id: 3,
            img: '../assets/Galery/Bathroom/3.jpeg'
        },
        {
            id: 4,
            img: '../assets/Galery/Bathroom/4.jpeg'
        },
        {
            id: 5,
            img: '../assets/Galery/Bathroom/5.jpeg'
        },
        {
            id: 6,
            img: '../assets/Galery/Bathroom/6.jpeg'
        },
        {
            id: 7,
            img: '../assets/Galery/Bathroom/7.jpeg'
        },
        {
            id: 8,
            img: '../assets/Galery/Bathroom/8.jpeg'
        },
        {
            id: 9,
            img: '../assets/Galery/Bathroom/9.jpeg'
        },
        {
            id: 10,
            img: '../assets/Galery/Bathroom/10.jpeg'
        }
        ,
        {
            id: 11,
            img: '../assets/Galery/Bathroom/11.jpeg'
        }
    ]

    const photos = data.map(photo => {
        return (
            <>
                <div>
                    <img class="h-96 w-full object-cover rounded-lg hover:scale-105 duration-200"
                        src={photo.img} alt="Image1" />
                </div>
            </>
        )
    })

    return (
        <>
            {photos}
        </>
    )

}

export default Bathroom;