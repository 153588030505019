import React from "react";

function DecksAndFence() {

    return (
        <>

            <main>
                <section className="w-full h-96 grid place-items-center md:flex md:justify-between items-center px-20 bg-black">
                    <div>
                        <h1 className="text-3xl md:text-5xl font-semibold text-center mb-4 text-green-titles p-5">Decks and Fence</h1>
                        <p className="text-white text-lg md:text-xl p-3">
                        Enhance outdoor living with custom decks and fences.
                        </p>
                    </div>
                    <div className='bg-banner-three w-[50%] h-[100%] bg-center bg-cover hidden md:block'></div>
                </section>

                <section className='w-full p-5 flex-flex-col justify-center items-center space-y-8 my-16'>
                    <h1 className="text-3xl text-center text-green-titles font-semib">
                        Decks and Fence
                    </h1>

                    <div className="w-[70%] flex flex-col mx-auto justify-center items-center space-y-7">
                        <p className="text-lg md:text-xl text-black text-justify leading-relaxed">
                        Decks and fences are integral components of any well-designed outdoor space. A custom deck allows you to create an outdoor living area perfect for entertaining, relaxing, or spending time with family. Whether made of wood, composite, or vinyl, decks provide a practical extension of your home where you can enjoy the outdoors in comfort. You can opt for various designs—multi-level, wrap-around, or a simple patio extension—to suit your home’s architecture and your personal style.
                        </p>
                        <p className="text-lg md:text-xl text-black text-justify leading-relaxed ">
                        Fences serve multiple purposes, from providing privacy and security to adding a decorative element to your yard. They can be designed in a variety of materials, such as wood for a natural look, vinyl for low maintenance, or metal for durability. A well-built fence not only enhances the aesthetic of your property but also defines boundaries, making your outdoor space safer and more private. Together, decks and fences improve your home’s curb appeal and increase its overall value.
                        </p>
                        <div className=" flex flex-col gap-4 md:flex-row items-center justify-center">
                            <img src="../assets/Material/fence1.jpg" alt="foton" className="w-full md:w-[50%] h-96" />
                            <img src="../assets/Material/fence2.jpg" alt="foton" className="w-full md:w-[50%] h-96" />
                        </div>
                    </div>

                </section>

                <div className='w-full flex justify-center mb-12'>
                    <a href='/services'>
                        <div className='bg-green-titles p-4 w-[200px] rounded-lg text-xl text-white font-semibold flex justify-center hover:bg-green-fos hover:border hover:cursor-pointer transition-all duration-500'>All Services</div>
                    </a>
                </div>
            </main>
        
        </>
    )

}

export default DecksAndFence