import React, { useEffect } from "react";
import AOS from "aos"
import 'aos/dist/aos.css'

function Rooms() {
    useEffect(() => {
        AOS.init()
    }, [])

    const data = [
        {
            id: 1,
            img: '../assets/Galery/Rooms/1.jpg'
        },
        {
            id: 2,
            img: '../assets/Galery/Rooms/2.jpg'
        },
        {
            id: 3,
            img: '../assets/Galery/Rooms/3.jpg'
        },
        {
            id: 4,
            img: '../assets/Galery/Rooms/4.jpg'
        },
        {
            id: 5,
            img: '../assets/Galery/Rooms/5.jpg'
        }
    ]

    const photos = data.map(photo => {
        return (
            <>
                <div>
                    <img class="h-96 w-full object-cover rounded-lg hover:scale-105 duration-200"
                        src={photo.img} alt="Image1" />
                </div>
            </>
        )
    })

    return (
        <>
            {photos}
        </>
    )

}

export default Rooms;