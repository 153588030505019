import React from "react";
import 'boxicons/css/boxicons.min.css';

function Footer() {
    return (
        <>
            <footer class="bg-black text-white p-8 py-16 w-full flex items-center justify-center">
                <div class="container mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-10">
                    <div className="bg-white mx-auto">
                        <a href="/">
                            <img className='w-[300px] h-[250px]' src="../assets/BC_Logote.png" alt="Logo" />
                        </a>
                    </div>

                    <div>
                        <h5 class="text-xl font-bold text-green-titles mb-3">Content</h5>
                        <ul class="space-y-2">
                            <li>
                                <a href="/" class="text-white hover:text-gray-300 text-sm">Home</a>
                            </li>
                            <li>
                                <a href="/about" class="text-white hover:text-gray-300 text-sm">About</a>
                            </li>
                            <li>
                                <a href="/services" class="text-white hover:text-gray-300 text-sm">Services</a>
                            </li>
                            <li>
                                <a href="/projects" class="text-white hover:text-gray-300 text-sm">Projects</a>
                            </li>
                            <li>
                                <a href="/contact" class="text-white hover:text-gray-300 text-sm">Contact</a>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h5 class="text-xl font-bold text-green-titles mb-3">Social Media</h5>
                        <ul class=" flex flex-row items-center space-x-2">
                            <li>    
                                <a href="https://www.facebook.com/people/BC-Quality-General-Contractor/61554250582980/" class="text-white hover:text-green-fos transition-all duration-300 text-sm">
                                    <i class='bx bxl-facebook-circle text-3xl'  ></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://wa.me/19712034446" class="text-white  hover:text-green-fos transition-all duration-300 text-sm">
                                    <i class='bx bxl-whatsapp text-3xl' ></i>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h5 class="text-xl font-bold text-green-titles mb-3">Location</h5>
                        <p class="text-gray-300 w-[70%] text-sm">2585 SE Marigold Ct hillsboro OR 97123 97123</p>
                    </div>

                    <div className="space-y-3">
                        <h5 class="text-xl font-bold text-green-titles mb-3">Contact us</h5>
                        <p class="text-gray-300 text-sm">971-203-4446</p>
                        <p class="text-gray-300 text-sm">(503) 462-8206</p>

                        <div>
                            <p class="text-gray-300 text-sm">bcqualitygeneral</p>
                            <p class="text-gray-300 text-sm">@gmail.com</p>
                        </div>

                    </div>
                </div>
            </footer>
            <div className='w-full mx-auto bg-black p-5 overflow-hidden shadow-sm z-40 flex flex-row justify-center space-x-6'>
                <img className="w-36" src="../assets/IICRC.png" alt="IICRC" />
                {/* <img className="w-[90px]" src="../assets/lead-safe.png" alt="EPA" /> */ }
            </div>
            <div class="w-full mx-auto px-5 overflow-hidden bg-green-fos shadow-sm z-40 flex justify-center">
                <div class="text-white font-bold p-4 flex flex-col items-center space-y-4">
                    <p>&copy; 2024 BC Quality General Contractor. All rights reserved.</p>
                    <p>Licensed, Insured & Bonded CCB #246585</p>
                </div>
            </div>
        </>
    );
}
export default Footer;