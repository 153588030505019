import React from 'react';
import 'boxicons/css/boxicons.min.css';

function Contact() {
    return (
        <>
            {/* banner */}
            <section className="w-full h-[300px] grid place-items-center md:flex md:justify-between items-center px-20 bg-black">
                <div>
                    <h1 className="text-3xl md:text-5xl font-semibold text-center mb-4 text-green-titles p-5">Contact</h1>
                </div>
                <div className='bg-banner-three w-[50%] h-[100%] bg-center bg-cover hidden md:block'></div>
            </section>


            {/* Form */}
            <section className='w-full grid grid-cols-1 place-items-center justify-center'>
                <h2 className="text-3xl font-bold mt-14 text-green-titles">Send us a message</h2>
                <form action="https://formsubmit.co/bcqualitygeneral@gmail.com" method="POST" className='w-[60%] my-12 space-y-4'>
                    <label className="input input-bordered flex items-center gap-2 bg-white h-[70px]">
                        <i class='text-green-fos bx bxs-user' ></i>
                        <input name='name' type="text" className="grow border-gray-200 focus:border-green-fos w-[90%]" placeholder="Name" />
                    </label>
                    <label className="input input-bordered flex items-center gap-2 bg-white h-[70px]">
                        <i class='text-green-fos bx bxs-envelope'></i>
                        <input name='email' type="text" className="grow border-gray-200 focus:border-green-fos w-[90%]" placeholder="Email" />
                    </label>
                    <label className="input input-bordered flex items-center gap-2 bg-white h-[70px]">
                        <i class='text-green-fos bx bxs-phone-call'></i>
                        <input name='phone' type="number" className="grow border-gray-200 focus:border-green-fos w-[90%]" placeholder="Phone" />
                    </label>
                    <label className="input input-bordered flex items-center gap-2 bg-white h-[130px]">
                        <i class='text-green-fos bx bxs-message-dots' ></i>
                        <input name='message' type="text" className="grow border-gray-200 focus:border-green-fos w-[90%] h-[85%]" placeholder="Message" />
                    </label>
                    <div className='w-full flex justify-center my-2'>
                        <a href='/'>
                            <button type='submit' className='bg-green-fos p-4 w-[200px] rounded-lg text-white font-bold flex justify-center
                            hover:bg-white hover:text-green-fos hover:border hover:cursor-pointer transition-all duration-500'>Submit</button>
                        </a>
                    </div>
                </form>
            </section>

            {/* Maps */}
            <section className='w-full grid grid-cols-1 place-items-center justify-center mb-16'>
                <h2 className="text-3xl font-semibold mt-14 text-green-titles mb-8">Location</h2>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2796.4813956971766!2d-122.95868072354818!3d45.50038713102323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5495103d8e92c36f%3A0x1afbffe5cf90f829!2s2585%20SE%20Marigold%20Ct%2C%20Hillsboro%2C%20OR%2097123%2C%20EE.%20UU.!5e0!3m2!1ses-419!2smx!4v1725769587631!5m2!1ses-419!2smx"
                    className='rounded-2xl w-[65%] h-[400px]' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section>
        </>
    )
};

export default Contact;