import React, { useState } from 'react';

function Navbar() {
    const [isOpen, setMenu] = useState(false);

    function openMenu() {
        setMenu(true);
    }

    function closeMenu() {
        setMenu(false);
    }

    return (
        <>
            <div className="w-full mx-auto px-5 overflow-hidden bg-green-fos shadow-lg z-40">
                <div className="flex flex-col md:flex-row justify-between items-center py-2">
                    <div className="text-white font-bold">
                        <a href="mailto:bcqualitygeneral@gmail.com">bcqualitygeneral@gmail.com</a>
                    </div>
                    <div className="flex space-x-10 text-white font-bold">
                        <a href="tel:+19712034446">Phone: +1 971-203-4446</a>
                        <a href="/contact">Oregon, USA</a>
                    </div>
                </div>
            </div>

            <header>
                <nav className="bg-white p-4 shadow-xl">
                    <div className="flex items-center justify-between">
                        {/* Logo */}
                        <div className="text-2xl text-green-fos">
                            <a href="/">
                                <img className='w-[110px]' src="../assets/BC_Quality_General_Construction_Logo.png" alt="Logo" />
                            </a>
                        </div>

                        {/* Enlaces del menú para pantallas grandes */}
                        <div className="hidden md:flex space-x-10 items-center">
                        <img className="w-24" src="../assets/IICRC.png" alt="IICRC" />
                            <a href="/" className="text-black font-semibold hover:text-green-fos">Home</a>
                            <a href="/about" className="text-black font-semibold hover:text-green-fos">About us</a>
                            <a href="/services" className="text-black font-semibold hover:text-green-fos">Services</a>
                            <a href="/projects" className="text-black font-semibold hover:text-green-fos">Projects</a>
                            <a href="/contact" className="text-black font-semibold hover:text-green-fos">Contact</a>
                        </div>

                        {/* Botón menú hamburguesa para pantallas pequeñas */}
                        <button id="menu-btn" className="block md:hidden focus:outline-none" onClick={openMenu}>
                            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                            </svg>
                        </button>
                    </div>
                </nav>

                {/* Menú deslizante para pantallas pequeñas */}
                <div id="menu"
                    className={`fixed top-0 right-0 bg-green-titles transition-all duration-500 ease-in-out z-50 h-screen overflow-hidden
                        ${isOpen ? 'w-[70%] md:w-0 opacity-100' : 'w-0 opacity-0'}`}
                >
                    <div className="flex justify-end mr-6 mt-14">
                        <button id="close-btn" className="text-white focus:outline-none" onClick={closeMenu}>
                            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    <div className="flex flex-col items-center  h-screen space-y-6">
                        <img className='w-full bg-white' src="../assets/BC_Quality_General_Construction_Logo.png" alt="Logo" />
                        <a href="/" className="text-white text-2xl font-semibold hover:text-gray-300">Home</a>
                        <a href="/about" className="text-white text-2xl font-semibold hover:text-gray-300">About us</a>
                        <a href="/services" className="text-white text-2xl font-semibold hover:text-gray-300">Services</a>
                        <a href="/projects" className="text-white text-2xl font-semibold hover:text-gray-300">Projects</a>
                        <a href="/contact" className="text-white text-2xl font-semibold hover:text-gray-300">Contact</a>
                        <img className="w-36" src="../assets/IICRC.png" alt="IICRC" />
                    </div>
                </div>
            </header>
        </>
    );
}

export default Navbar;
