import React, { useState, useEffect } from 'react';
import 'boxicons/css/boxicons.min.css';
import { IoHomeSharp } from "react-icons/io5"
import { TbFence } from "react-icons/tb";
import { MdWaterDamage } from "react-icons/md";


function Home() {

  const images = [
    '../assets/carousel/one.jpeg',
    '../assets/carousel/two.jpg',
    '../assets/carousel/three.jpg'
  ]

  const [current, setBackground] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setBackground((prev) =>
        prev === images.length - 1 ? 0 : prev + 1
      )
    }, 7000)

    return () => clearInterval(interval)
  }, [images.length])

  return (
    <main className='m-0'>
      {/* Imagen de ancho completo */}
      <section className="relative w-full h-[450px] hover:bg-gradient-to-t from-black bg-cover bg-center transition-all duration-1000 ease-in-out" style={{ backgroundImage: `url(${images[current]})` }}>
        {/* <div className='absolute inset-0 bg-black bg-opacity-50'></div> */}
      </section>

      {/* Services */}
      <div className='h-auto my-12'>
        <section className='flex flex-col items-center p-4 md:p-8 w-full'>
          <h3 className="text-3xl font-bold mb-4 text-green-titles">Our Services</h3>

          {/* Carousel container */}
          <div className='w-full md:w-3/4 lg:w-2/3'>
            <ul className="carousel carousel-center bg-white rounded-box w-full space-x-4 p-4 overflow-x-auto snap-x snap-mandatory">

              <li className="carousel-item snap-center">
                <a href="/WaterDamage">
                  <div className="border p-6 bg-white text-green-fos shadow-lg rounded-lg w-[250px] md:w-[300px] h-[190px] grid place-items-center hover:bg-green-fos hover:text-white hover:cursor-pointer transition-all duration-500 hover:animate-pulse">
                    <h3 className="text-xl text-center font-bold mb-4">Water Damage Restoration 24/7</h3>
                    {/* <i class='bx bx-water text-7xl'></i> */}
                    <MdWaterDamage className='text-7xl' />
                  </div>
                </a>
              </li>

              <li className="carousel-item snap-center">
                <a href="/InternalAndExternal">
                  <div className="border p-6 bg-white text-green-fos shadow-lg rounded-lg w-[250px] md:w-[300px] h-[190px] grid place-items-center hover:bg-green-fos hover:text-white hover:cursor-pointer transition-all duration-500 hover:animate-pulse">
                    <h3 className="text-xl text-center font-bold mb-4">Painting Exterior and Interior</h3>
                    <i className='bx bx-paint-roll text-7xl'></i>
                  </div>
                </a>
              </li>

              <li className="carousel-item snap-center">
                <a href="/Remodeling">
                  <div className="border p-6 bg-white text-green-fos shadow-lg rounded-lg w-[250px] md:w-[300px] h-[190px] grid place-items-center hover:bg-green-fos hover:text-white hover:cursor-pointer transition-all duration-500 hover:animate-pulse">
                    <h3 className="text-xl text-center font-bold mb-4">Remodeling</h3>
                    {/* <i className='bx bx-wrench text-7xl'></i> */}
                    <IoHomeSharp className='text-7xl'/>
                  </div>
                </a>
              </li>

              <li className="carousel-item snap-center">
                <a href="/DecksAndFence">
                  <div className="border p-6 bg-white text-green-fos shadow-lg rounded-lg w-[250px] md:w-[300px] h-[190px] grid place-items-center hover:bg-green-fos hover:text-white hover:cursor-pointer transition-all duration-500 hover:animate-pulse">
                    <h3 className="text-xl text-center font-bold mb-4">Decks and Fence</h3>
                    {/* <i className='bx bx-layer text-7xl'></i> */}
                    <TbFence className='text-7xl' />
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </section>

        {/* Button */}
        <div className='w-full flex justify-center mb-12'>
          <a href='/services'>
            <div className='bg-green-titles p-4 w-[200px] rounded-lg text-xl text-white font-semibold flex justify-center
      hover:bg-green-fos hover:border hover:cursor-pointer transition-all duration-500'>
              All Services
            </div>
          </a>
        </div>
      </div>



      {/* About Us */}
      <section className="bg-gray-50 flex flex-col  py-16 px-8 my-12">
        <div className='flex flex-col md:flex-row items-center'>
          <div className="md:w-3/4 p-4">
            <h2 className="text-3xl font-bold mb-4 text-green-titles">About Us</h2>
            <p className='text-black text-justify'>
              At BC Quality General Contractor, we are dedicated to delivering high-quality construction and renovation services. With years of experience, we specialize in painting, remodeling, deck and fence installations, and water damage restoration. Our goal is to enhance the beauty and functionality of your home, inside and out.
            </p>
            <p className='text-black text-justify'>
              We pride ourselves on excellent craftsmanship, reliability, and clear communication. Whether you're updating your home or recovering from damage, we’re here to make the process smooth and stress-free. Trust us to bring your vision to life with care and precision.
            </p>
          </div>
          <div className="md:w-1/3 p-4">
            <img
              src="../assets/carousel/one.jpeg"
              alt="About Us"
              className="w-full h-auto object-cover rounded-lg z-0"
            />
          </div>
        </div>
        {/* Button */}
        <div className='w-full flex justify-center my-6'>
          <a href='/About'>
            <div className='bg-green-titles p-4 w-[200px] rounded-lg text-xl text-white font-semibold flex justify-center hover:bg-green-fos hover:border hover:cursor-pointer transition-all duration-500'>
              Learn more
            </div>
          </a>
        </div>

      </section>


      {/* Why choose us? */}
      <section className="flex flex-col items-center py-16 px-8 ">
        <h4 className="text-3xl font-bold mb-8 text-green-titles w-full flex justify-center">Why choose us?</h4>
        <div className='w-full h-auto grid md:grid-cols-3 gap-4 place-items-center text-black'>
          <div className='flex flex-col items-center w-[80%] space-y-3 border p-6 rounded-xl bg-white'>
            <h3 className="text-2xl font-bold mb-4 text-green-fos">Expert Team</h3>
            <i className="bx bxs-star text-4xl mb-4 text-green-fos"></i>
            <p>Our experienced professionals ensure top-quality craftsmanship on every project.</p>
          </div>
          <div className='flex flex-col items-center w-[80%] space-y-3 border p-6 rounded-xl bg-white'>
            <h5 className='text-2xl font-bold text-green-fos'>Faster Response</h5>
            <i class='text-4xl text-green-fos bx bx-run' ></i>
            <p>Our team will arrive as soon as possible to take care of the damage and return your property to its best condition.</p>
          </div>
          <div className='flex flex-col items-center w-[80%] space-y-3 border p-6 rounded-xl bg-white'>
            <h5 className='text-2xl font-bold text-green-fos'>Guarantee</h5>
            <i class='text-4xl text-green-fos bx bx-shield' ></i>
            <p>We ensure complete customer satisfaction from the fast response at the point of contact to quality service</p>
          </div>
        </div>
      </section>



      {/* Reviews */}
      <section className="mt-16 pt-16 pb-14 w-full bg-gray-100 flex flex-col items-center">
        <h4 className="text-3xl font-bold mb-8 text-green-titles">Reviews</h4>


        {/* Carousel container */}
        <div className="w-full md:w-3/4 lg:w-2/3">
          <ul className="carousel carousel-center rounded-box w-full space-x-4 p-4 overflow-x-auto snap-x snap-mandatory">

            <li className="carousel-item snap-center">
              <div className="border p-6 bg-white text-black shadow-lg rounded-lg w-[300px] md:w-[500px] flex flex-col items-center">
                <h3 className="text-xl text-center font-bold w-[80%] mb-4 p-2 border-b text-green-titles">Daniel Muñoz</h3>
                <p className="text-justify">"I hired BC Quality General Contractor to repaint the interior and exterior of my home, and I couldn't be happier with the results! The team was professional, punctual, and paid great attention to detail. They helped me choose the perfect colors, and the transformation was amazing. I highly recommend them for anyone looking to refresh their home!"</p>
              </div>
            </li>

            <li className="carousel-item snap-center">
              <div className="border p-6 bg-white text-black shadow-lg rounded-lg w-[300px] md:w-[500px] flex flex-col items-center">
                <h3 className="text-xl text-center font-bold w-[80%] mb-4 p-2 border-b text-green-titles">John Smith</h3>
                <p className="text-justify">"We had some serious water damage in our basement, and BC Quality General Contractor came to the rescue. They repaired everything quickly and efficiently, and the basement looks even better than before. Their team was incredibly responsive and made the whole process stress-free. I would definitely use their services again!"</p>
              </div>
            </li>

            <li className="carousel-item snap-center">
              <div className="border p-6 bg-white text-black shadow-lg rounded-lg w-[300px] md:w-[500px] flex flex-col items-center">
                <h3 className="text-xl text-center font-bold w-[80%] mb-4 p-2 border-b text-green-titles">Bob Adams</h3>
                <p className="text-justify">"BC Quality General Contractor did a fantastic job remodeling our kitchen and bathroom. They worked with us every step of the way, offering great ideas to improve the layout and functionality. The craftsmanship was excellent, and the project was completed on time. We're thrilled with how everything turned out!"</p>
              </div>
            </li>

          </ul>
        </div>

        {/* Arrow Icon */}
        {/* <i className='bx bx-right-arrow-alt text-7xl md:hidden text-green-fos animate-pulse mt-8'></i> */}

        {/* Button */}
        <div className='w-full flex justify-center my-6'>
          <a href='/projects'>
            <div className='bg-green-titles p-4 w-[200px] rounded-lg text-xl text-white font-semibold flex justify-center hover:bg-green-fos hover:border hover:cursor-pointer transition-all duration-500'>
              Our Projects
            </div>
          </a>
        </div>

      </section>

    </main>
  );
}

export default Home;