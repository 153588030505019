import React, { useEffect } from 'react';
import 'boxicons/css/boxicons.min.css';
import AOS from "aos"
import 'aos/dist/aos.css'
import { IoHomeSharp } from "react-icons/io5"
import { TbFence } from "react-icons/tb";
import { MdWaterDamage } from "react-icons/md";

function Services() {

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <>

            <main className="overflow-x-hidden">

                {/* banner */}
                <section className="w-full h-[300px] grid place-items-center md:flex md:justify-between items-center px-20 bg-black">
                    <div>
                        <h1 className="text-3xl md:text-5xl font-semibold text-center mb-4 text-green-titles p-5">Services</h1>
                    </div>
                    <div className='bg-banner-one w-[50%] h-[100%] bg-center bg-cover hidden md:block'></div>
                </section>

                <div className="flex flex-col justify-center items-center p-6 gap-4 my-14">

                    {/* Card 4 */}
                    <div data-aos="flip-up" className="flex flex-col md:flex-row w-full max-w-full bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100">

                        <div className="flex flex-col justify-between p-6 w-full md:w-2/3">

                            <div className="p-6 text-green-fos grid place-items-center mb-4">
                                <h3 className="text-xl font-bold mb-4">Water Damage Restoration</h3>
                                {/* <i class='bx bx-droplet text-7xl'></i> */}
                                <MdWaterDamage className='text-7xl' />
                            </div>

                            <p className="font-normal text-justify text-gray-700 mb-6">Water Damage Restoration repair involves removing water, drying the area, and repairing damaged materials like drywall and flooring. Quick action prevents mold and structural damage, restoring your home’s safety and stability.
                            </p>

                            <div className="flex justify-center">
                                <a href='/WaterDamage' className="py-2 px-4 bg-green-fos text-white font-bold rounded-md hover:bg-green-titles">Learn More</a>
                            </div>
                        </div>

                        <img className="overflow-hiden h-[400px] object-cover w-full md:w-1/3 md:rounded-r-lg" src="../assets/Material/2.jpeg" alt="Imagen" />
                    </div>

                    {/* Card 1 */}
                    <div data-aos="flip-up" className="flex flex-col md:flex-row w-full max-w-full bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100">

                        <img className="overflow-hiden h-[400px] order-1 md:order-none object-cover w-full md:w-1/3 md:rounded-l-lg" src="../assets/Material/House.jpg" alt="Imagen" />

                        <div className="order-none md:order-1 flex flex-col justify-between p-6 w-full md:w-2/3">

                            <div className="p-6 text-green-fos grid place-items-center mb-4">
                                <h3 className="text-xl text-center font-bold mb-4">Painting Exterior and Interior</h3>
                                <i className='bx bx-paint-roll text-7xl'></i>
                            </div>

                            <p className="font-normal text-justify text-gray-700 mb-6">
                                Exterior and Interior painting and remodeling breathe new life into your home. A fresh coat of paint updates the look and adds protection, while remodeling enhances functionality and aesthetic appeal.
                            </p>

                            <div className="flex justify-center">
                                <a href='/InternalAndExternal' className="py-2 px-4 bg-green-fos text-white font-bold rounded-md hover:bg-green-titles">Learn More</a>
                            </div>
                        </div>
                    </div>

                    {/* Card 2 */}
                    <div data-aos="flip-up" className="flex flex-col md:flex-row w-full max-w-full bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100">

                        <div className="flex flex-col justify-between p-6 w-full md:w-2/3">

                            <div className="p-6 text-green-fos grid place-items-center mb-4">
                                <h3 className="text-xl font-bold mb-4">Remodeling</h3>
                                {/* <i class='bx bx-wrench text-7xl'></i> */}
                                <IoHomeSharp className='text-7xl' />
                            </div>

                            <p className="font-normal text-justify text-gray-700 mb-6">
                                Remodeling enhances both the appearance and functionality of your home. It allows for modern updates, improved layouts, and increased property value. Whether it’s a kitchen, bathroom, or entire home, remodeling transforms your space to better suit your lifestyle and preferences.
                            </p>

                            <div className="flex justify-center">
                                <a href='/Remodeling' className="py-2 px-4 bg-green-fos text-white font-bold rounded-md hover:bg-green-titles">Learn More</a>
                            </div>
                        </div>

                        <img className="overflow-hiden h-[400px] object-cover w-full md:w-1/3 md:rounded-r-lg" src="../assets/Material/rem1.jpg" alt="Imagen" />
                    </div>

                    {/* Card 3 */}
                    <div data-aos="flip-up" className="flex flex-col md:flex-row w-full max-w-full bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100">

                        <img className="overflow-hiden h-[400px] order-1 md:order-none object-cover w-full md:w-1/3 md:rounded-l-lg" src="../assets/Material/fence1.jpg" alt="Imagen" />

                        <div className="order-none md:order-1 flex flex-col justify-between p-6 w-full md:w-2/3">

                            <div className="p-6 text-green-fos grid place-items-center mb-4">
                                <h3 className="text-xl font-bold mb-4">Decks and Fence</h3>
                                {/* <i class='bx bx-layer text-7xl'></i> */}
                                <TbFence className='text-7xl' />
                            </div>

                            <p className="font-normal text-justify text-gray-700 mb-6">
                                Decks and fences enhance your outdoor space by adding functionality and beauty. A well-built deck extends your living area outdoors, while a fence offers privacy, security, and aesthetic appeal. Both are valuable additions to any home’s exterior.
                            </p>

                            <div className="flex justify-center">
                                <a href='/DecksAndFence' className="py-2 px-4 bg-green-fos text-white font-bold rounded-md hover:bg-green-titles">Learn More</a>
                            </div>
                        </div>
                    </div>



                </div>

            </main>

        </>
    )
};

export default Services;