import React from "react";

function WaterDamage() {
    return (
        <>

            <main>
                <section className="w-full h-96 grid place-items-center md:flex md:justify-between items-center px-20 bg-black">
                    <div>
                        <h1 className="text-3xl md:text-5xl font-semibold text-center mb-4 text-green-titles p-5">Water Damage Restoration</h1>
                        <p className="text-white text-lg md:text-xl p-3">
                        Restore your home swiftly after water damage.
                        </p>
                    </div>
                    <div className='bg-banner-four w-[50%] h-[100%] bg-center bg-cover hidden md:block'></div>
                </section>

                <section className='w-full p-5 flex-flex-col justify-center items-center space-y-8 my-16'>
                    <h1 className="text-3xl text-center text-green-titles font-semib">
                    Water Damage Restoration
                    </h1>

                    <div className="w-[70%] flex flex-col mx-auto justify-center items-center space-y-7">
                        <p className="text-lg md:text-xl text-black text-justify leading-relaxed">
                        Water damage is a serious issue that can affect the structural integrity of your home. Whether caused by a burst pipe, a leaking roof, or flooding, the effects of water damage are far-reaching. The first step in reparation is to stop the source of the water and begin the drying process. Water extraction is essential to prevent mold and mildew from developing, which can lead to health issues and further deterioration of materials.
                        </p>
                        <p className="text-lg md:text-xl text-black text-justify leading-relaxed ">
                        Once the area is dry, damaged components such as drywall, flooring, and insulation are repaired or replaced. In some cases, this involves more extensive repairs like rebuilding walls, replacing electrical systems, or repairing plumbing. Thorough inspections ensure that no hidden water damage remains. By addressing water damage swiftly, you prevent long-term problems, like foundation issues or mold infestations, and ensure your home remains safe and habitable.
                        </p>
                        <div className=" flex flex-col gap-4 md:flex-row items-center justify-center">
                            <img src="../assets/Material/water.png" alt="foton" className="w-full md:w-[80%] h-96" />
                        </div>
                    </div>

                </section>

                <div className='w-full flex justify-center mb-12'>
                    <a href='/services'>
                        <div className='bg-green-titles p-4 w-[200px] rounded-lg text-xl text-white font-semibold flex justify-center hover:bg-green-fos hover:border hover:cursor-pointer transition-all duration-500'>All Services</div>
                    </a>
                </div>
            </main>
        
        </>
    );
}

export default WaterDamage;