import React, { useEffect } from "react";
import AOS from "aos"
import 'aos/dist/aos.css'

function Painting() {

    useEffect(() => {
        AOS.init()
    }, [])

    const data = [
        {
            id: 1,
            img: '../assets/Galery/Painting/1.jpg'
        },
        {
            id: 2,
            img: '../assets/Galery/Painting/2.jpg'
        },
        {
            id: 3,
            img: '../assets/Galery/Painting/3.jpg'
        },
        {
            id: 4,
            img: '../assets/Galery/Painting/4.jpg'
        },
        {
            id: 5,
            img: '../assets/Galery/Painting/5.jpg'
        },
        {
            id: 6,
            img: '../assets/Galery/Painting/6.jpg'
        },
        {
            id: 7,
            img: '../assets/Galery/Painting/7.jpg'
        },
        {
            id: 8,
            img: '../assets/Galery/Painting/8.jpg'
        },
        {
            id: 9,
            img: '../assets/Galery/Painting/9.jpg'
        },
        {
            id: 10,
            img: '../assets/Galery/Painting/10.jpg'
        }
        ,
        {
            id: 11,
            img: '../assets/Galery/Painting/11.jpg'
        }
        ,
        {
            id: 12,
            img: '../assets/Galery/Painting/12.jpg'
        }
        ,
        {
            id: 13,
            img: '../assets/Galery/Painting/13.jpg'
        }
        ,
        {
            id: 14,
            img: '../assets/Galery/Painting/14.jpg'
        }
        ,
        {
            id: 15,
            img: '../assets/Galery/Painting/15.jpg'
        }
        ,
        {
            id: 16,
            img: '../assets/Galery/Painting/16.jpg'
        }
        ,
        {
            id: 17,
            img: '../assets/Galery/Painting/17.jpg'
        }
    ]

    const photos = data.map(photo => {
        return (
            <>
                <div>
                    <img class="h-96 w-full object-cover rounded-lg hover:scale-105 duration-200"
                        src={photo.img} alt="Image1" />
                </div>
            </>
        )
    })

    return (
        <>
            {photos}
        </>
    )
}

export default Painting